// @flow
import React, { useRef } from "react"
import gsap from "gsap"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import Decoration from "../../client/compositions/decoration"
import Slider from "../../core/components/slider"
import Animations from "../../core/classes/animations"
import Timers from "../../core/classes/timers"
import by from "../../core/functions/by"

import products from "../../client/data/products.json"

const DISPLACEMENT = 20
const AUTOPLAY_INTERVAL = 5

// <ProductsViewerComponent />
type Props = {
  productSlugs: Array<String>,
  autoPlay: boolean,
}

type State = {
  index: number,
}

/**
 * Products viewer
 */
class ProductsViewerComponent extends React.Component<Props, State> {
  static defaultProps = {
    productSlugs: [],
    autoPlay: true,
  }

  state = {
    index: 0,
  }

  sliderElement: ?Flickity = null
  autoplayInterval: ?intervalID = null

  animations = new Animations("gsap")
  timers = new Timers()

  getFeaturedProducts() {
    const { productSlugs } = this.props

    return productSlugs
      .map((slug) => products.find(by("slug", slug)))
      .filter((product) => product)
  }

  handleCellClick(event: Event) {
    const { currentTarget: cell } = event

    if (!(cell instanceof HTMLDivElement)) {
      return
    }

    const index = parseInt(cell.dataset.index, 10)

    this.setState({ index })
    this.autoPlay()
  }

  autoPlay(step = 1) {
    const { autoPlay } = this.props
    if (!autoPlay || !AUTOPLAY_INTERVAL) {
      return
    }

    // clear previous autoPlay
    if (this.autoPlayInterval) {
      clearInterval(this.autoPlayInterval)
    }

    this.autoPlayInterval = this.timers.setInterval(() => {
      // get auto play index range
      let min = 0
      let max = this.getFeaturedProducts().length - 1
      let { index } = this.state

      // go to next index
      let nextIndex = index + step
      if (nextIndex < min) nextIndex = max
      if (nextIndex > max) nextIndex = min

      this.setState({ index: nextIndex })
    }, AUTOPLAY_INTERVAL * 1000)
  }

  animateBetween(outIndex, inIndex) {
    const getElementsByIndex = (index) => ({
      featuredImage: `.featured-image[data-index="${index}"]`,
      message: `.message[data-index="${index}"]`,
      title: `.message[data-index="${index}"] .title div span`,
      content: `.message[data-index="${index}"] .content`,
      button: `.message[data-index="${index}"] .button`,
    })

    const outElements = getElementsByIndex(outIndex)
    const inElements = getElementsByIndex(inIndex)

    // quit existing animations
    this.animations.cleanUp()

    // register new animation
    this.animations.register(
      gsap
        .timeline({
          defaults: {
            duration: 1,
            ease: "expo.out",
          },
        })
        // set general initial values
        .set(Object.values(outElements), {
          zIndex: 1,
        })
        .set(Object.values(inElements), {
          display: "block",
          zIndex: 0,
          opacity: 0,
        })
        .set([inElements.title, inElements.button], {
          x: 0,
          y: "100%",
          opacity: 1,
        })
        .set(inElements.content, {
          x: 0,
          y: DISPLACEMENT,
        })
        // set individual initial values
        .set(inElements.message, {
          display: "grid",
        })
        // fade out
        .to(Object.values(outElements), {
          opacity: 0,
          display: "none",
        })
        // displace
        .to(
          [outElements.title, outElements.content],
          {
            y: DISPLACEMENT,
          },
          "<"
        )
        // fade in
        .to(
          inElements.featuredImage,
          {
            opacity: 1,
          },
          "<"
        )
        .to(
          [inElements.message, inElements.content],
          {
            opacity: 1,
          },
          "-=0.25"
        )
        // displace
        .to(
          [inElements.title, inElements.content, inElements.button],
          {
            x: 0,
            y: "0%",
            zIndex: 20,
          },
          "<"
        )
    )
  }

  componentDidMount() {
    this.autoPlay()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { index: prevIndex } = prevState || {}
    const { index } = this.state

    if (index !== prevIndex) {
      // animate viewer
      this.animateBetween(prevIndex, index)

      // animate slider
      const { flkty } = this.sliderElement || {}
      if (flkty) {
        flkty.select(index)
      }
    }
  }

  componentWillUnmount() {
    this.animations.cleanUp()
    this.timers.cleanUp()
  }

  render() {
    const featuredProducts = this.getFeaturedProducts()

    return (
      <section className="products-viewer">
        {featuredProducts.map(({ viewerBanner }, i) => (
          <div
            key={i}
            className="featured-image"
            aria-hidden={i}
            style={{ display: !i ? "grid" : "none" }}
            data-index={i}
          >
            <Decoration image={viewerBanner} disableLazyLoad={true} />
          </div>
        ))}
        {featuredProducts.map(({ name, url, description }, i) => (
          <Anchor
            to={url}
            key={url}
            className="message"
            aria-hidden={i}
            style={{ display: !i ? "grid" : "none" }}
            data-index={i}
          >
            <h1 className="title">
              <div>
                <span>{name}</span>
              </div>
              <div>
                <span>Collection</span>
              </div>
            </h1>
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            <div className="button-wrapper">
              <Button image="arrow-right">Discover</Button>
            </div>
          </Anchor>
        ))}
        <Slider
          className="nav"
          scrollBar={true}
          flickityProps={{
            options: {
              cellAlign: "left",
              contain: true,
              wrapAround: false,
              imagesLoaded: true,
              setGallerySize: false,
            },
          }}
          ref={(sliderElement) => (this.sliderElement = sliderElement)}
        >
          {featuredProducts.map(({ name, viewerThumbnail, url }, i) => (
            <Anchor
              to={url}
              key={url}
              //onClick={this.handleCellClick.bind(this)}
              data-index={i}
            >
              <h3 className="title">{name} Collection</h3>
              <Decoration image={viewerThumbnail} disableLazyLoad={true} />
            </Anchor>
          ))}
        </Slider>
      </section>
    )
  }
}

/**
 * Compose
 */
const ProductsViewer = ProductsViewerComponent

/**
 * Export
 */
export default ProductsViewer
