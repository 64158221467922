import React from "react"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import Overlap from "../../client/compositions/overlap"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ProductsViewer from "../../client/compositions/products-viewer"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import Tagline from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Index Page
 * @see https://zpl.io/Vkqr9YG
 */
function IndexPage() {
  return (
    <article id="page-index" className="page-content">
      {quickMenu}

      <Row id="products-viewer" role="banner">
        <Block className="block-media">
          <ProductsViewer
            productSlugs={[
              "product-inspire-wall-mounted",
              "product-premier",
              "product-distinct",
            ]}
          />
        </Block>
      </Row>

      <Row id="modular-system" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">
            Versatile <br />
            Displays
          </h2>
          <div className="content">
            Invinity racks are extremely versatile, not only are the individual
            lines customizable they are also interchangable so you can redesign
            or update your racking when you want a different feel.
          </div>
          <Button to="products" image="icons/arrow-right.svg">
            See Products
          </Button>
        </Block>
        <Block className="block-media">
          <Slider
            flickityProps={{
              options: {
                cellAlign: "center",
                wrapAround: true,
                pageDots: true,
                prevNextButtons: true,
                imagesLoaded: true,
                setGallerySize: false,
                autoPlay: 4500,
              },
            }}
          >
            <Decoration image="index/modular-01.gif" disableLazyLoad={true} />
            <Decoration image="index/modular-02.gif" disableLazyLoad={true} />
            <Decoration image="index/modular-03.gif" disableLazyLoad={true} />
          </Slider>
        </Block>
      </Row>

      <Row id="versatile-displays" className="content-plus-media flipped">
        <Block className="block-content">
          <h2 className="title">
            Modular <br />
            System
          </h2>
          <div className="content">
            Our systems can be easily connected for extended heights and
            patterened horizontally to fit your needs.
          </div>
          <Button to="products" image="icons/arrow-right.svg">
            See Products
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            style={{
              width: "50vw",
              height: "59vw",
            }}
            sizes={[
              { w: "75%", h: "70%" },
              { w: "57%", h: "61%" },
            ]}
            styles={[
              { transform: `translateY(10px)`, zIndex: 99 },
              { transform: `translateY(30px)` },
            ]}
          >
            <Decoration image="index/versatile-01.gif" />
            <DecorationFluid image="index/versatile-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Row id="material-craftwork" className="content-plus-media">
        <Block className="block-content">
          <h2 className="title">
            <span style={{ whiteSpace: "nowrap" }}>Material &</span>
            <br />
            Craftwork
          </h2>
          <div className="content">
            Racks are constructed from high grade stainless steel and machined
            to a fine finish with tight fitting connections. Two stock finishes
            that work with every design but also custom finishes to fit the
            designers vision.
          </div>
          <Button to="products" image="icons/arrow-right.svg">
            See Products
          </Button>
        </Block>
        <Block className="block-media">
          <Overlap
            position="top right"
            style={{
              width: "48vw",
              height: "44vw",
            }}
            sizes={[
              { w: "86%", h: "63%" },
              { w: "55%", h: "57%" },
            ]}
          >
            <DecorationFluid image="index/material-01.medium.jpg" />
            <DecorationFluid image="index/material-02.small.jpg" />
          </Overlap>
        </Block>
      </Row>

      <Row id="custom-builds" className="image-gallery">
        <Block className="block-media">
          <Slider
            flickityProps={{
              options: {
                cellAlign: "center",
                wrapAround: false,
                pageDots: true,
                prevNextButtons: true,
                imagesLoaded: true,
                setGallerySize: false,
              },
            }}
          >
            <Decoration
              image="index/custom-builds-01.jpg"
              disableLazyLoad={true}
            />
            <Decoration
              image="index/custom-builds-02.jpg"
              disableLazyLoad={true}
            />
            <Decoration
              image="index/custom-builds-03.jpg"
              disableLazyLoad={true}
            />
          </Slider>
        </Block>
        <Block className="block-content">
          <h2 className="title">
            Custom
            <br />
            Builds
          </h2>
          <div className="content">
            Have a design but don’t know how to build it? Need turn key services
            that include consulting and design services as well as specialty and
            stock racking, specialty equipment and custom fabrication then click
            here for our sister company that created a lot of the surrounding
            aesthetics and conditioning of the items in our portfolio. Let us
            make your vision.
          </div>
          <Button to="products/custom-build" image="icons/arrow-right.svg">
            Discover
          </Button>
        </Block>
      </Row>

      <Row id="quotes" className="quote-gallery">
        <Block className="block-media">
          <Slider
            flickityProps={{
              options: {
                autoPlay: 3000,
                pauseAutoPlayOnHover: false,
                cellAlign: "center",
                wrapAround: true,
                pageDots: true,
                prevNextButtons: false,
              },
            }}
          >
            <blockquote className="quote">
              <em className="content">
                They brought our vision magnificently to reality.
              </em>
              <cite className="attribution">
                Michael Wolk, Michael Wolk Design
              </cite>
            </blockquote>
            <blockquote className="quote">
              <em className="content">
                Their ability to work with our Interior designers to create
                fully functional controlled wine displays while maintaining the
                desired aesthetics is amazing.
              </em>
              <cite className="attribution">Gert Kopera, Jumeirah Hotels</cite>
            </blockquote>
            <blockquote className="quote">
              <em className="content">
                Over the moon, fantastic reaction to huge controlled environment
                wine display in the private dining area of our hotel and, more
                importantly, it worked.
              </em>
              <cite className="attribution">
                Jorge L. Zequeira, St. Regis Resorts
              </cite>
            </blockquote>
          </Slider>
        </Block>
      </Row>
      <Tagline />
    </article>
  )
}

export default IndexPage
