class Timers {
  /**
   * Constructor
   */
  constructor() {
    this.timeouts = []
    this.intervals = []
  }

  /**
   * setTimeout
   */
  setTimeout(handler, delay) {
    if (typeof handler !== "function") {
      throw new Error("Invalid handler")
    }
    if (typeof delay !== "number") {
      throw new Error("Invalid delay")
    }

    let timeoutId = setTimeout(handler, delay)
    this.timeouts.push(timeoutId)

    return timeoutId
  }

  /**
   * setInterval
   */
  setInterval(handler, delay) {
    if (typeof handler !== "function") {
      throw new Error("Invalid handler")
    }
    if (typeof delay !== "number") {
      throw new Error("Invalid delay")
    }

    let intervalId = setInterval(handler, delay)
    this.intervals.push(intervalId)

    return intervalId
  }

  /**
   * Remove all
   */
  cleanUp() {
    this.timeouts.forEach((timeout) => clearTimeout(timeout))
    this.intervals.forEach((timeout) => clearInterval(timeout))

    this.timeouts = []
    this.intervals = []
  }
}

export default Timers
