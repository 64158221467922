class Animations {
  /**
   * Constructor
   */
  constructor(cleanUpHandler) {
    if (cleanUpHandler === "gsap") {
      cleanUpHandler = (tl) => {
        tl.progress(1).clear().kill()
      }
    }

    if (typeof cleanUpHandler !== "function") {
      throw new Error("Invalid clean up handler")
    }

    this.cleanUpHandler = cleanUpHandler
    this.cleanUpTargets = []
  }

  /**
   * Create animtion
   */
  register(cleanUpTarget) {
    this.cleanUpTargets.push(cleanUpTarget)
  }

  /**
   * Remove all
   */
  cleanUp() {
    this.cleanUpTargets.forEach((cleanUpTarget) =>
      this.cleanUpHandler(cleanUpTarget)
    )
    this.cleanUpTargets = []
  }
}

export default Animations
